import React from 'react'

const SVGKits = () => {
  return (
    <div style={{ display: 'none' }}>
      {/* <!-- svg xlink --> */}
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol id="logo">
          <path
            d="M38.498 44.55h-3.354v-4.92h-1.122v5.943h4.191a.636.636 0 0 0 .2-.027.558.558 0 0 0 .352-.211.984.984 0 0 0 .119-.448v-.34ZM40.906 39.635h-.867v5.938h1.122v-5.68a.257.257 0 0 0-.255-.258ZM51.191 40.705h2.808a.753.753 0 0 0 .62-.231.975.975 0 0 0 .118-.441v-.335H50.829a1.577 1.577 0 0 0-1.421 1.539l-.011 2.74a1.592 1.592 0 0 0 1.427 1.6h3.169a.756.756 0 0 0 .62-.231.977.977 0 0 0 .118-.442v-.334h-3.433a.7.7 0 0 1-.69-.625v-.809h3.254a.651.651 0 0 0 .5-.229.971.971 0 0 0 .117-.442v-.334H50.611v-.8c.043-.35.212-.61.58-.626ZM48.738 39.731h-.349c-.054 0-.656.025-.863.349-.024.038-.123.246-.267.56l-1.733 3.9-1.595-3.663c-.21-.475-.36-.8-.39-.853-.208-.324-.793-.3-.848-.3h-.348l2.4 5.314s.092.2.162.368a.265.265 0 0 0 .22.147v.015h.982l2.63-5.843Z"
            fill="#3b2f32"
          />
          <path
            fill="url(#mesk)"
            d="M31.574 17.233c.012-7.1 5.274-12.845 11.777-12.857 6.5.013 11.765 5.758 11.777 12.857a13.881 13.881 0 0 1-.836 4.74A21.13 21.13 0 0 0 43.436 18.7a18.233 18.233 0 0 0-8.371 2.056 9.786 9.786 0 0 0 2.317 3.667 14.265 14.265 0 0 1 6.054-1.346 17.111 17.111 0 0 1 8.753 2.641 11.374 11.374 0 0 1-8.838 4.376c-6.5-.014-11.765-5.757-11.777-12.857m33.189-3.891a9.464 9.464 0 0 1 18.9 0 9.464 9.464 0 0 1-18.9 0M74.214 0a13.342 13.342 0 1 0 0 26.682 13.475 13.475 0 0 0 11.408-6.262c-1.3 5.562-5.912 9.683-11.408 9.669-6.768-.016-11.132-2.575-15.566-5.4l-.84-.538a18.529 18.529 0 0 0 1.329-6.922C59.136 7.714 52.071 0 43.352 0S27.567 7.714 27.565 17.233a18.493 18.493 0 0 0 1.51 7.356l-.23.166c-3.979 2.852-7.537 5.346-13.058 5.336-6.5-.014-11.765-5.757-11.776-12.857.011-7.1 5.274-12.845 11.776-12.857a11.6 11.6 0 0 1 9.98 6.044 20.961 20.961 0 0 1 2.007-4.395A15.243 15.243 0 0 0 15.786 0C7.068 0 0 7.714 0 17.233s7.069 17.231 15.786 17.233c6.712 0 11.328-3.218 15.262-6.057l.186-.133a14.967 14.967 0 0 0 24.508-.376l.881.566c4.47 2.867 9.857 6 17.591 6C82.932 34.467 90 26.751 90 17.233S82.934 0 74.214 0Z"
          />
        </symbol>
        {/* <!-- 背景 --> */}
        <symbol id="pokerBg">
          <rect width="25.58" height="37.511" rx="3" style={{ fill: '#f5f5f5' }} />
        </symbol>
        {/* <!-- 方塊 --> */}
        <symbol id="pokerDiamond">
          <path
            fill="#d31b02"
            d="m14.863 23.234-2.07-3.586-2.071 3.586-2.071 3.586 2.071 3.586 2.071 3.586 2.07-3.586 2.071-3.586Z"
          />
        </symbol>
        {/* <!-- 梅花--> */}
        <symbol id="pokerClub">
          <path d="M15.93 24.972a3.59 3.59 0 0 0-.276.014 3.458 3.458 0 0 0 .613-2.094 3.5 3.5 0 0 0-3.29-3.35 3.477 3.477 0 0 0-3.661 3.472 3.458 3.458 0 0 0 .615 1.972 3.59 3.59 0 0 0-.276-.014 3.477 3.477 0 0 0-3.472 3.655 3.5 3.5 0 0 0 3.454 3.3 3.462 3.462 0 0 0 2.151-.735l-.756 2.061a.469.469 0 0 0 .441.631h2.639a.469.469 0 0 0 .441-.631l-.755-2.061a3.457 3.457 0 0 0 2.15.735 3.5 3.5 0 0 0 3.454-3.3 3.477 3.477 0 0 0-3.472-3.655Z" />
        </symbol>
        {/* <!-- 黑桃 --> */}
        <symbol id="pokerSpade">
          <path d="m18.369 24.129-5.577-5.577-5.576 5.577a3.943 3.943 0 0 0 0 5.576 3.931 3.931 0 0 0 2.788 1.155 3.944 3.944 0 0 0 1.767-.42l-.676 1.844a.469.469 0 0 0 .44.631h2.64a.47.47 0 0 0 .441-.631l-.65-1.773a3.942 3.942 0 0 0 4.4-.806 3.93 3.93 0 0 0 1.155-2.788 3.93 3.93 0 0 0-1.152-2.788Z" />
        </symbol>
        {/* <!-- 愛心 --> */}
        <symbol id="pokerHeart">
          <path
            fill="#d31b02"
            d="m18.369 27.109-5.576 5.577-5.576-5.577a3.927 3.927 0 0 1-1.156-2.788 3.926 3.926 0 0 1 1.156-2.788 3.942 3.942 0 0 1 5.576 0 3.932 3.932 0 0 1 2.788-1.156 3.932 3.932 0 0 1 2.788 1.156 3.943 3.943 0 0 1 0 5.576Z"
          />
        </symbol>
        <symbol id="pokerNum1">
          <path d="M14.892 13.689h-4.214l-.881 2.533H7.832l4.109-10.877h1.7l4.116 10.877h-1.972Zm-3.683-1.524h3.152l-1.576-4.512Z" />
        </symbol>
        <symbol id="pokerNum2">
          <path d="M16.557 16.222H9.244v-1.248l3.623-3.951a9.058 9.058 0 0 0 1.117-1.461 2.373 2.373 0 0 0 .332-1.176 1.787 1.787 0 0 0-.437-1.255 1.514 1.514 0 0 0-1.177-.478 1.748 1.748 0 0 0-1.367.538 2.112 2.112 0 0 0-.485 1.471H9.034a3.555 3.555 0 0 1 .453-1.79 3.1 3.1 0 0 1 1.292-1.235 4.094 4.094 0 0 1 1.938-.442 3.682 3.682 0 0 1 2.5.8 2.761 2.761 0 0 1 .916 2.2 3.742 3.742 0 0 1-.46 1.708 9.7 9.7 0 0 1-1.5 2.027l-2.66 2.847h5.05Z" />
        </symbol>
        <symbol id="pokerNum3">
          <path d="M11.52 9.954h1.106a1.984 1.984 0 0 0 1.326-.433 1.547 1.547 0 0 0 .489-1.233 1.686 1.686 0 0 0-.407-1.206 1.635 1.635 0 0 0-1.244-.429 1.732 1.732 0 0 0-1.2.422 1.411 1.411 0 0 0-.47 1.1H9.304a2.741 2.741 0 0 1 .445-1.524 2.986 2.986 0 0 1 1.24-1.073 4.049 4.049 0 0 1 1.782-.385 3.74 3.74 0 0 1 2.559.819 2.872 2.872 0 0 1 .93 2.274 2.312 2.312 0 0 1-.467 1.378 2.933 2.933 0 0 1-1.207.975 2.673 2.673 0 0 1 1.363.964 2.637 2.637 0 0 1 .467 1.569 2.916 2.916 0 0 1-1.005 2.331 3.9 3.9 0 0 1-2.64.867 3.87 3.87 0 0 1-2.57-.837 2.754 2.754 0 0 1-1-2.226h1.815a1.52 1.52 0 0 0 .482 1.165 1.826 1.826 0 0 0 1.3.448 1.884 1.884 0 0 0 1.33-.448 1.68 1.68 0 0 0 .486-1.3 1.7 1.7 0 0 0-.508-1.322 2.174 2.174 0 0 0-1.51-.464h-1.068Z" />
        </symbol>
        <symbol id="pokerNum4">
          <path d="M15.419 12.345h1.352v1.457h-1.352v2.42h-1.816v-2.42h-4.7l-.052-1.106 4.691-7.351h1.876Zm-4.662 0h2.846V7.802l-.134.239Z" />
        </symbol>
        <symbol id="pokerNum5">
          <path d="m9.595 10.828.59-5.483h5.842v1.569h-4.333l-.3 2.6a3.352 3.352 0 0 1 1.7-.434 3.031 3.031 0 0 1 2.416.993 3.936 3.936 0 0 1 .863 2.676 3.621 3.621 0 0 1-.956 2.64 3.491 3.491 0 0 1-2.622.983 3.653 3.653 0 0 1-2.476-.848 3.064 3.064 0 0 1-1.065-2.237h1.755a1.918 1.918 0 0 0 .568 1.213 1.726 1.726 0 0 0 1.21.422 1.561 1.561 0 0 0 1.3-.6 2.582 2.582 0 0 0 .467-1.629 2.279 2.279 0 0 0-.515-1.572 1.8 1.8 0 0 0-1.42-.579 2.369 2.369 0 0 0-.837.13 2.371 2.371 0 0 0-.747.512Z" />
        </symbol>
        <symbol id="pokerNum6">
          <path d="M14.82 5.292v1.5h-.225a3.6 3.6 0 0 0-2.435.837 3.5 3.5 0 0 0-1.083 2.309 2.942 2.942 0 0 1 2.233-.92 2.787 2.787 0 0 1 2.276 1.016 4.006 4.006 0 0 1 .832 2.623 3.733 3.733 0 0 1-.975 2.685 3.362 3.362 0 0 1-2.558 1.028 3.287 3.287 0 0 1-2.641-1.2 4.731 4.731 0 0 1-1.013-3.153v-.62a6.658 6.658 0 0 1 1.394-4.482 5 5 0 0 1 4.008-1.622Zm-1.95 5.193a1.919 1.919 0 0 0-1.095.335 1.975 1.975 0 0 0-.728.9v.554a3.252 3.252 0 0 0 .508 1.923 1.551 1.551 0 0 0 1.315.713 1.535 1.535 0 0 0 1.277-.6 2.525 2.525 0 0 0 .471-1.592 2.559 2.559 0 0 0-.478-1.606 1.524 1.524 0 0 0-1.27-.629Z" />
        </symbol>
        <symbol id="pokerNum7">
          <path d="m16.6 6.354-4.348 9.868h-1.9l4.341-9.42H9.067V5.345h7.538Z" />
        </symbol>
        <symbol id="pokerNum8">
          <path d="M16.162 8.214a2.531 2.531 0 0 1-.411 1.423 2.851 2.851 0 0 1-1.121.982 3.1 3.1 0 0 1 1.311 1.084 2.726 2.726 0 0 1 .46 1.554 2.854 2.854 0 0 1-.971 2.274 3.815 3.815 0 0 1-2.585.841 3.83 3.83 0 0 1-2.6-.844 2.851 2.851 0 0 1-.975-2.271 2.766 2.766 0 0 1 .463-1.57 2.984 2.984 0 0 1 1.3-1.068 2.835 2.835 0 0 1-1.109-.982 2.544 2.544 0 0 1-.408-1.423 2.832 2.832 0 0 1 .9-2.205 3.466 3.466 0 0 1 2.42-.814 3.477 3.477 0 0 1 2.428.814 2.833 2.833 0 0 1 .898 2.205Zm-1.576 4.953a1.792 1.792 0 0 0-.483-1.3 1.692 1.692 0 0 0-1.273-.5 1.681 1.681 0 0 0-1.267.493 1.809 1.809 0 0 0-.474 1.308 1.749 1.749 0 0 0 .467 1.277 1.717 1.717 0 0 0 1.289.478 1.723 1.723 0 0 0 1.281-.463 1.756 1.756 0 0 0 .46-1.293Zm-.232-4.887a1.691 1.691 0 0 0-.411-1.169 1.412 1.412 0 0 0-1.106-.458 1.418 1.418 0 0 0-1.1.437 1.684 1.684 0 0 0-.4 1.19 1.686 1.686 0 0 0 .407 1.189 1.43 1.43 0 0 0 1.1.441 1.432 1.432 0 0 0 1.1-.441 1.686 1.686 0 0 0 .41-1.186Z" />
        </symbol>
        <symbol id="pokerNum9">
          <path d="M14.566 11.673a2.846 2.846 0 0 1-2.13.963 2.846 2.846 0 0 1-2.286-1 3.951 3.951 0 0 1-.851-2.641 4.325 4.325 0 0 1 .433-1.95 3.232 3.232 0 0 1 1.233-1.36 3.493 3.493 0 0 1 1.852-.486 3.144 3.144 0 0 1 2.608 1.226 5.162 5.162 0 0 1 .963 3.28v.508a6.705 6.705 0 0 1-1.329 4.489 5.006 5.006 0 0 1-3.967 1.569h-.217v-1.5h.262a3.709 3.709 0 0 0 2.465-.773 3.222 3.222 0 0 0 .964-2.325Zm-1.749-.456a1.836 1.836 0 0 0 1.024-.314 2.069 2.069 0 0 0 .74-.867v-.71a3.4 3.4 0 0 0-.494-1.934 1.483 1.483 0 0 0-1.277-.732 1.466 1.466 0 0 0-1.251.646 2.708 2.708 0 0 0-.467 1.632 2.685 2.685 0 0 0 .47 1.651 1.491 1.491 0 0 0 1.255.628Z" />
        </symbol>
        <symbol id="pokerNum10">
          <path d="M10.304 16.222H8.496V7.483l-2.667.909V6.868l4.244-1.561h.231ZM19.872 11.643a5.926 5.926 0 0 1-.878 3.537 3.1 3.1 0 0 1-2.67 1.192 3.142 3.142 0 0 1-2.656-1.162 5.684 5.684 0 0 1-.915-3.44V9.883a5.855 5.855 0 0 1 .877-3.519 3.134 3.134 0 0 1 2.679-1.173 3.149 3.149 0 0 1 2.663 1.143 5.664 5.664 0 0 1 .9 3.423Zm-1.815-2.032a4.609 4.609 0 0 0-.418-2.246 1.428 1.428 0 0 0-1.33-.712 1.426 1.426 0 0 0-1.307.676 4.253 4.253 0 0 0-.441 2.11v2.465a4.719 4.719 0 0 0 .426 2.271 1.428 1.428 0 0 0 1.337.747 1.4 1.4 0 0 0 1.292-.69 4.447 4.447 0 0 0 .441-2.163Z" />
        </symbol>
        <symbol id="pokerNumJ">
          <path d="M14.527 5.345h1.882v7.627a3.243 3.243 0 0 1-.974 2.48 3.6 3.6 0 0 1-2.574.92 3.724 3.724 0 0 1-2.63-.86 3.1 3.1 0 0 1-.926-2.391h1.882a1.793 1.793 0 0 0 .43 1.305 1.669 1.669 0 0 0 1.244.437 1.547 1.547 0 0 0 1.214-.5 2.014 2.014 0 0 0 .452-1.393Z" />
        </symbol>
        <symbol id="pokerNumQ">
          <path d="M17.379 11.067a6.716 6.716 0 0 1-.489 2.678 4.363 4.363 0 0 1-1.372 1.8l1.868 1.457-1.225 1.106-2.316-1.83a4.748 4.748 0 0 1-.979.1 4.349 4.349 0 0 1-2.349-.646 4.244 4.244 0 0 1-1.6-1.842 6.45 6.45 0 0 1-.571-2.757v-.612a6.618 6.618 0 0 1 .564-2.809 4.241 4.241 0 0 1 1.591-1.865 4.326 4.326 0 0 1 2.349-.646 4.394 4.394 0 0 1 2.372.639 4.186 4.186 0 0 1 1.588 1.838 6.612 6.612 0 0 1 .568 2.79Zm-1.9-.567a4.724 4.724 0 0 0-.687-2.787 2.261 2.261 0 0 0-1.943-.949 2.222 2.222 0 0 0-1.908.96 4.648 4.648 0 0 0-.706 2.708v.635a4.774 4.774 0 0 0 .691 2.758 2.229 2.229 0 0 0 1.938.993 2.23 2.23 0 0 0 1.939-.957 4.816 4.816 0 0 0 .676-2.794Z" />
        </symbol>
        <symbol id="pokerNumK">
          <path d="m11.675 11.524-1.232 1.307v3.391h-1.89V5.345h1.89v5.1l1.046-1.293 3.182-3.81h2.286l-4.042 4.818 4.274 6.059h-2.242Z" />
        </symbol>
      </svg>
    </div>
  )
}

SVGKits.propTypes = {}

export default SVGKits
