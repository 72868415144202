import { lazy, Suspense } from 'react'
import history from 'utils/history'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'

const Home = lazy(() => import('pages/HomePage/HomePage'))

const Routes = () => {
  return (
    <ErrorBoundary>
      <ConnectedRouter history={history}>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/" exact component={Home} />
          </Switch>
        </Suspense>
      </ConnectedRouter>
    </ErrorBoundary>
  )
}

export default Routes
