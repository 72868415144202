import { createSlice, createAction } from '@reduxjs/toolkit'
import getLanguage from 'utils/getLanguage'
import { parse } from 'query-string'

export const getData = createAction('global/getData')

const initialState = {
  language: getLanguage(),
  token: parse(window.location.search).token,
  gamecode: parse(window.location.search).gamecode,
  dollarsign: parse(window.location.search).dollarsign,
  theme: 'light',
}

export const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTheme: (state, { payload }) => {
      state.theme = payload
    },
  },
})

export const { setTheme } = slice.actions
export default slice.reducer
