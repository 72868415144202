export const LANGUAGE = {
  EN: 'en',
  ZH_CN: 'zh-CN',
  ZH_TW: 'zh-TW',
  KO: 'ko',
}

export const TABLE_TYPES = {
  BARCCART: 'barccart',
  MARBLE: 'marble',
}
