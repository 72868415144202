import { LANGUAGE } from 'constants/index'
import { parse } from 'query-string'

const getLanguage = () => {
  const allowLanguage = Object.values(LANGUAGE).map((el) => el.toLowerCase())
  const { language } = parse(window.location.search) || {}
  if (language && allowLanguage.includes(language.toLowerCase())) return language
  return LANGUAGE.EN
}

export default getLanguage
